import React from 'react';
import { Tabs } from 'antd';
import { FileImageOutlined, EditOutlined, ProductOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LogoSettingListView from '../components/officer/LogoSettingListView';
import SignSettingListView from '../components/officer/SignSettingListView';
import DDSProductSettingListView from '../components/officer/DDSProductSettingListView';

export default function DDSSettingView() {
  const [activeKey, setActiveKey] = React.useState("logo");
  const onTabsChange = (key) => setActiveKey(key);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={onTabsChange}
        destroyInactiveTabPane={true}
        items={[
          {
            key: "logo", 
            label: <span><FileImageOutlined /> {t("logo_setting")}</span>, 
            children: <LogoSettingListView/>,
          },
          {
            key: "signed", 
            label: <span><EditOutlined /> {t("signed_setting")}</span>, 
            children: <SignSettingListView />,
          },
          {
            key: "product", 
            label: <span><ProductOutlined /> {t("product_setting")}</span>, 
            children: <DDSProductSettingListView />,                                                                
          }
        ]} />
    </div>
  )
}