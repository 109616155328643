import React from "react";
import { Button, Col, Modal, Popconfirm, theme, Tooltip, message } from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Header from "../common/Header";
import { SECONDARY_COLOR } from "../../constants/color";
import TSTable from "../common/TSTable";
import { URL_DDS, URL_PLANTATIONS } from "../../constants/urls";
import { DELETE } from "../../frameworks/HttpClient";
import { WORKER_TYPE, WORKER_TYPE_TEXT } from "../../constants/string";
import { useTranslation } from 'react-i18next';
import LogoSettingModal from "../officer/LogoSettingModal";
import ErrorMessages from "../common/ErrorMessages";
import { getFileName } from "../../frameworks/Util";


export default function LogoSettingListView(props){
  const [target, setTarget] = React.useState(null);
  const tableRef = React.useRef(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { t, i18n } = useTranslation();

  const {
		token: { colorWarning, colorError },
	} = theme.useToken();

  const mockData = [{

  }]

  const columns = [
    {
      title: t("company_name"),
      dataIndex: "name",  
    },
    {
      title: t("address"),
      dataIndex: "address",
    },
    {
      title: t("logo"),
      dataIndex: "logo",
      width : 260,
      render: (value) => {
        return (
          <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: 260}} >
            <a onClick={() => window.open(value, "_blank")}>
              {getFileName(value)}
            </a>
          </div>
        )
      }
    },

    {
      title: t("factory"),
      dataIndex: "factories_detail",
      render: (value) => {
        return (
          value.map(detail => (
            <div>{detail.name_en} ({detail.short_name})</div>
          ))
        )
      }
    },
    
    {
      title: "Action",
      width: 100,
      dataIndex: "id",

      render: (value, original) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip title={t("actions.edit")}>
              <Button
                type={"primary"}
                style={{ margin: 4, background: colorWarning }}
                icon={<EditOutlined />}
                onClick={() => setTarget(original)}
              />
            </Tooltip>
            
            <Popconfirm
              title={t("delete_logo_warning")}
              onConfirm={() => handleDelete(value)}
              okButtonProps={{ danger: true }}
              okText={t("delete")}
              cancelText={t("cancel")}>
                <Button
                  type={"primary"}
                  style={{ margin: 4, background: colorError }}
                  icon={<DeleteOutlined />}/>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithSameParams();
    }
  };

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_DDS.DDS_LOGO}${id}`);
      refreshData();  
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  };

  return(
    <div>
      <ErrorMessages message={errorMessages}/>
      {contextHolder}
      <div style={{textAlign: "right", marginTop: 16, marginBottom: 8}}>
        <Button
          type={"primary"}
          icon={<PlusCircleOutlined />}
          style={{
            background: SECONDARY_COLOR,
          }}
          onClick={() => setTarget('add')}>
          {t("add_logo")}
        </Button>
      </div>

      <Col md={24} sm={24} xs={24}>
        <TSTable
          ref={tableRef}
          columns={columns}
          url={`${URL_DDS.DDS_LOGO}`}
          rowKey={"id"}
          size={"small"}
          pageSize={10}
          style={{ marginBottom: 20 }}
        />
      </Col>

      <LogoSettingModal
        open={target ? true : false}
        onClose={() => {
          setTarget(null)
        }}
        onUpdate={() => {
          setTarget(null)
          refreshData()
        }}
        onSaved={() => {
          messageApi.open({
            type: 'success',
            content: 'DDS Logo saved successfully',
          });
        }}
        target={target}
      />

    </div>
  )
}