import React from "react";
import Header from "../common/Header";
import {  Alert, Button, Col, DatePicker, Divider, Form, Image, Input, Modal,  Row,  Select, Switch, theme } from "antd";
import UploadFile from "../common/UploadFile";
import { POST } from "../../frameworks/HttpClient";
import { URL_PLANTATIONS } from "../../constants/urls";
import TextArea from "antd/es/input/TextArea";
import { configConsumerProps } from "antd/es/config-provider";
import { useTranslation } from 'react-i18next';

export default function CheckPlantationModal(props) {
  const documentRef = React.useRef(null)
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  
  const {
    token: { colorPrimary, colorWarning, colorInfo },
  } = theme.useToken();

  const handleUpdateFile = async() => {
    setErrorMessages(null)
    setLoading(true)
    let formData = form.validateFields();

      if(documentRef.current){
        const document = await documentRef.current.getData();
  
        try { 

            // Change State checking to waiting
            if(props.data.status === 'checking'){
              let data = {
                plantation : props.data.id,
                action : 'check',
                note : formData.note ? formData.note : null
              }

              const respone = await POST(URL_PLANTATIONS.UPDATE_LOG, data)
              document.map(async(item) => {
                let logDocument = {}
              
                logDocument = {
                  log : respone.data.id,
                  document : item.originFileObj
                }
              
                await POST(URL_PLANTATIONS.UPDATE_LOG_DOCUMENT, logDocument, true)
              }
            )
            props.onUpdate();
          }

            // recheck plantations
            if(props.data.status === 'approved'){
              let data = {
                documents : document[0].originFileObj ,
                append : false
              }
              await POST(`${URL_PLANTATIONS.PLANTATIONS}${props.data.id}/recheck/`, data, true)
              props.onUpdate();
            }


          } catch (error) {
            console.log(error)
            setErrorMessages(error.errorMessages)
          } finally {
            setLoading(false)
            await documentRef.current.clearFile()
          }
      }
  }

  const handleReject = async() => {
    let data = {}
    let formData = await form.validateFields();
    console.log(formData)
    if(formData.note){
      try{
        data = {
          plantation : props.data.id,
          action : 'reject',
          note: formData.note ? formData.note : null
        }
        await POST(URL_PLANTATIONS.UPDATE_LOG, data)
        props.onUpdate();
        documentRef.current.clearFile();
      } catch(error) {
        console.log(error)
      }
    } else {
      setErrorMessages('กรุณากรอกเหตุผลไม่อนุมัติ')
    }
    
  }

  const handleCancle = async() => {
    props.onClose()
    setLoading(false)
    setErrorMessages(null)
    await documentRef.current.clearFile()
  }

  return (

    <Modal
      style={{ width : 100 }}
		  open={props.open}
      onOk={handleUpdateFile}
		  onCancel={handleCancle}
      footer={[
        <div style={{ marginTop : 30}}>
          <Button onClick={handleCancle} style={{ marginLeft : 8}}>
            {t("cancel")}
          </Button>
          <Button type="primary" style={{ marginLeft : 8}} loading={loading} onClick={handleReject} danger>
            {t("reject")}
          </Button>
          <Button
            type="primary"
            loading={loading}
            onClick={handleUpdateFile}
            style={{ marginLeft : 8}}
          >
            {t("confirm")}
          </Button>
        </div>
      ]}
    >
      <Header title={t("plantations.update_survey")} style={{}}/>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: '1rem', textAlign: "left"}}
        />
      }
      <p>{t("plantations.detail_document")}</p>
      <Form form={form} layout="vertical" autoComplete="off">
        <UploadFile
          className={"upload-list-inline"}
          style = {{marginBottom : 16, background : colorPrimary, color : 'white'}}
          btnText = {t("plantations.upload_document")}
          maxCount = {1}
          ref = {documentRef}
        />
        <Form.Item name={"note"}>
          <TextArea  rows={4} placeholder={`${t("plantations.reason")}...`} defaultValue={''}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}
