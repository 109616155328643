import React from "react";
import Header from "../common/Header";
import {  Alert, Button, Col, DatePicker, Divider, Form, Image, Input, Modal,  Row,  Select, Switch, theme } from "antd";
import UploadFile from "../common/UploadFile";
import TextArea from "antd/es/input/TextArea";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_PLANTATIONS } from "../../constants/urls";
import { useTranslation } from 'react-i18next';

export default function ChangeStateModal(props) {
    const documentRef = React.useRef(null)
    const [form] = Form.useForm();
    const [documents, setDocuments] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const [errorMessages, setErrorMessages] = React.useState(null);
    const { t, i18n } = useTranslation();

    const {
      token: { colorPrimary, colorWarning, colorInfo },
    } = theme.useToken();

    const handleChangeState = async() => {
      setLoading(true)
      setErrorMessages(null)
      let document = [];
      let FormData = await form.validateFields();
      
      if(documentRef.current)
        document = await documentRef.current.getData();

        try {
            let data = {
              ...FormData,
              plantation : props.data.id,
              action : 'approve',
            }
            const respone = await POST(URL_PLANTATIONS.UPDATE_LOG, data)
            document.map(async(item) => {
              let logDocument = {
                log : respone.data.id,
                document : item.originFileObj
              }
              await POST(URL_PLANTATIONS.UPDATE_LOG_DOCUMENT, logDocument, true)
            })
            props.onUpdate(); 
        } catch (error) {
          setErrorMessages(error.errorMessage)
        } finally {
          setLoading(false)
        }

      documentRef.current.clearFile();
    }

    const handleCancle = async() => {
      props.onClose()
      await documentRef.current.clearFile()
      setErrorMessages(null)
    }

    const handleReject = async() => {
      let data = {}
      let FormData = await form.validateFields();
      console.log(FormData.note)
      if(FormData.note){
        try{
          data = {
            plantation : props.data.id,
            action : 'reject',
            note: FormData.note ? FormData.note : null
          }
          await POST(URL_PLANTATIONS.UPDATE_LOG, data)
          props.onUpdate();
          setErrorMessages(null)
        } catch(error) {
          console.log(error)
        }
      } else {
        setErrorMessages('กรอกเหตุผลการไม่อนุมัติ')
      }
      
      documentRef.current.clearFile();
    }

    React.useEffect(() => {
      if(props.open){
        form.resetFields();
      }
    },[props.open])


    return (
      <Modal
        width={700}
		    open={props.open}
        onOk={handleChangeState}
		    onCancel={handleCancle}
        footer={[
          <div style={{ marginTop : 30}}>
            <Button onClick={handleCancle} style={{ marginLeft : 8}}>
              {t("cancel")}
            </Button>
            <Button type="primary" style={{ marginLeft : 8}} loading={loading} onClick={handleReject} danger>
              {t("reject")}
            </Button>
            <Button
              type="primary"
              loading={loading}
              onClick={handleChangeState}
              style={{ marginLeft : 8}}
            >
              {t("approve")}
            </Button>
          </div>
        ]}
      >
      <Header title={props.data && t("plantations.approve_plantation")} style={{}}/>
        <Form form={form} layout="vertical" autoComplete="off">
        <Row>
          <Col md={12} sm={24} xs={24}>
            <p style={{color : colorPrimary, fontWeight : 'bold', fontSize : 20}}>{t("plantations.member_name")}: {props.data ? i18n.language === 'th' ? props.data.farmer_name : props.data.farmer_name_en : ""}</p>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <p style={{color : colorPrimary, fontWeight : 'bold', fontSize : 20}}>{t("plantations.deed_code")}: {props.data && props.data.title_deed_no}</p>
          </Col>
        </Row>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: '1rem', textAlign: "left"}}
          />
        }
          <p>{t("plantations.upload_document_evidence")}</p>
          <UploadFile
            className={"upload-list-inline"}
            dataList={documents}
            style = {{ marginBottom : 16 , background : colorPrimary, color : 'white'}}
            btnText = {t("plantations.upload_document_evidence")}
            maxCount = {1}
            ref = {documentRef}
          />
          <Form.Item name={"note"} label={t("note")}>
            <TextArea rows={4} placeholder={`${t("plantations.reason")}.....`} defaultValue={''}/>
          </Form.Item>

          <Form.Item name={"approved_by"} label={t("auditor")}>
            <Input placeholder={`${t("auditor")}`} defaultValue={''} style={{ width : "100%"}}/>
          </Form.Item>
        </Form>
      </Modal>
    )
}