import React from "react";
import { Input, Modal, theme, Button, Image, Select, Typography, Transfer, Divider, Row, Col } from 'antd'
import Form from "antd/es/form/Form";
import { UploadOutlined } from '@ant-design/icons';
import imageDefault from "../../assets/placeholderImage.png"
import { useTranslation } from 'react-i18next';
import ImgCrop from 'antd-img-crop';
import * as _ from 'lodash';
import ErrorMessages from "../common/ErrorMessages";
import { URL_DDS, URL_MEMBER } from "../../constants/urls";
import { PATCH, POST } from "../../frameworks/HttpClient";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function SignSettingModal(props) {
  const {open, target, onClose, onUpdate} = props;
  const { t, i18n } = useTranslation();
  const uploadSignRef = React.useRef(null);
  const [signImageData, setSignImageData] = React.useState(null);
  const [uploadSignFile, setUploadSignFile] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const isCreate = target === 'add';
  const [form] = Form.useForm();
  const factories = Form.useWatch('factories', form);

  const factoryDropdown = useDropdownItem({
    url: URL_MEMBER.FACTORY,
    manual: true, pagination: true, 
    params: { page_size: 999 },
    key: 'id', value: 'id', 
    label_renderer: (item) => i18n.language === "en" ? (item.name_en ? item.name_en : item.name) : (item.name ? item.name : item.name_en) ,
  });

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleOk = async() => {
    try {
      const data = await form.validateFields();
      if(isCreate){
        const response = await POST(URL_DDS.DDS_SIGNATURE, data)
        if (signImageData){
          const id = response ? response.data.id : target.id
          const image = {signature : signImageData}
          await PATCH(`${URL_DDS.DDS_SIGNATURE}${id}/`, image, true)  
        }
      } else {
        await PATCH(`${URL_DDS.DDS_SIGNATURE}${target.id}/`, data,)
        if (signImageData){
          const image = {signature : signImageData}
          await PATCH(`${URL_DDS.DDS_SIGNATURE}${target.id}/`, image, true)  
        }
      }
      props.onSaved()
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      factoryDropdown.fetchOptions();
      if(!isCreate){
        form.setFieldsValue({...target})
      }
    } else {
      form.resetFields(); 
      setErrorMessages(null)
      setUploadSignFile(null)
    }
  },[open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={isCreate ? t("add_sign") : t("edit_sign")}
      onOk={handleOk}
      okText={isCreate ? t('add') : t('edit')}
      cancelText={t('cancel')}
      width={900}
      style={{ top: 20 }}
      okButtonProps={{ style : { backgroundColor : isCreate ? colorPrimary : colorWarning}}}
    >
      <ErrorMessages message={errorMessages}/>
      <Form
        style={{marginTop: 0}}
        form={form}
        layout="vertical"
        autoComplete="off">

          <Row gutter={8}>
            <Col md={12} sm={16} xs={24}>

            <div style={{ textAlign: "center", height : 170}} className='section-wrapper'>
              <Image
                src={uploadSignFile 
                  ? uploadSignFile 
                  : (target && _.get(target, 'signature', false) ) 
                  ? _.get(target, 'signature', null)
                  : imageDefault}
                  style={{ height : 80 }}
              />
              <div>
                <Button 
                  style={{marginTop: 8}}
                  icon={<UploadOutlined />} 
                  onClick={() => uploadSignRef.current.click()}
                  >
                    {t("upload_sign")}
                </Button>
              </div>
              <div style={{marginTop: 12}}>
                <Typography.Text>({t("signature_auto_resize")})</Typography.Text>
              </div>
            </div>
            </Col>
          
          
            <Col md={12} sm={16} xs={24}>
              <div style={{ height: 170 }} className='section-wrapper'>
                <Form.Item 
                  name={"name"} 
                  label={t("name")} 
                  style={{marginTop: 12}}
                  rules={[{ required: true }]}>
                  <Input placeholder={`${t("name")}`} defaultValue={''}/>
                </Form.Item>

                <Form.Item 
                  name={"position"} 
                  label={t("position")} 
                  style={{marginTop: 12}}
                  rules={[{ required: true }]}>
                  <Input placeholder={`${t("position")}`} defaultValue={''}/>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider/>
          <Row>
          <Form.Item name={"factories"} label={`${t("factory")} (${t("logo_factory_info")})`}>
            <Transfer
              dataSource={factoryDropdown.options}
              titles={['', 'In Use']}
              oneWay
              showSearch
              targetKeys={factories}
              render={item => `${item.name_en} (${item.short_name})`}
              listStyle={{
                width: 400,
                height: 300,
              }}
              onChange={keys => {
                form.setFieldValue('factories', keys)
              }}
            />
          </Form.Item>
          </Row>

        </Form>

      <ImgCrop aspect={3/1} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadSignRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadSignFile(URL.createObjectURL(evt.target.files[0]))
              setSignImageData(evt.target.files[0])
              evt.target.value = null  // clear reference to enable select same file}
          }}}
        />
      </ImgCrop>

    </Modal>
  )
}