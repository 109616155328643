import { Form, Modal, theme, Row, Input, Col, message, Select, Spin, Button, DatePicker } from "antd";
import React from "react";
import { URL_MEMBER, URL_COMMON } from "../../constants/urls";
import { PATCH, GET } from "../../frameworks/HttpClient";
import _ from "lodash";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, LANGUAGE } from "../../constants/string";
import dayjs from 'dayjs';

export default function EditAdminCCDetailModal(props) {
  const [form] = Form.useForm();
  const [fetching, setFetching] = React.useState(false);
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { t, i18n } = useTranslation();

  const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  const fetchSubdistrict = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
      setSubdistricts(response.data.results.map((item) => {
        return {
          ...item,
          label: i18n.language === LANGUAGE.TH ? item.display_name : item.display_name_en,
          value: item.subdistrict_id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }


  const {
    token: { colorPrimary, colorWarning, colorInfo },
  } = theme.useToken();

  const fetchData = () => {
    setLoading(true);
    let data = props.data
    try {
      form.setFieldsValue({...data,
        license_expire_date : data.license_expire_date ? dayjs(data.license_expire_date) : null,
      })
      form.setFieldValue("name", i18n.language === LANGUAGE.TH ? data.name : data.name_en)
      form.setFieldValue("sub_district", {label : i18n.language === LANGUAGE.TH ?  data.sub_district_detail : data.sub_district_detail_en, value : data.sub_district})

    } catch (error) {
      message.error(error.errorMessage);
    } finally {
      setLoading(false);
    }
  }

  const handleEdit = async () => {
    try {
      let data = await form.validateFields();
      data['sub_district'] = data.sub_district.value
      if(data.license_expire_date){
        data['license_expire_date']= data.license_expire_date.format(DATE_FORMAT);
      }
      if (props.data) {
        console.log(data)
        await PATCH(`${URL_MEMBER.USER_COLLECTING_CENTER}`, data)
        props.onUpdate();
      }
    } catch (error) {
      console.log(error.errorMessage)
    }
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchSubdistrict();
  }, [debouncedCode])

  React.useEffect(() => {
    if (props.data) {
      fetchData();
    }
  }, [props.open])

  return (
    <Modal
      width={600}
      title={t("dashboard.collecting_center")}
      okText={t("edit")}
      confirmLoading={loading}
      okButtonProps={{ style: { background: colorWarning } }}
      open={props.open}
      onOk={handleEdit}
      onCancel={props.onClose}
      footer={[
        <Button loading={loading} onClick={props.onClose}>ยกเลิก</Button>,
        <ColorButton type='primary' override={colorWarning} loading={loading} onClick={handleEdit}>แก้ไข</ColorButton>,
      ]}>

      <Form
        // {...formItemLayout}
        form={form}
        autoComplete="off"
        layout="vertical"
        // labelCol ={{ span: 8 }}
      >

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col md={24} sm={24} xs={24}>

            <Form.Item
              name={"name"}
              label={t("profiles.name")}
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>

          </Col>

          <Col md={24} sm={24} xs={24}>

            <Form.Item
              name={"email"}
              label={t("email")}
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>

          </Col>

          <Col md={24} sm={24} xs={24}>

            <Form.Item
              name={"phone"}
              label={t("collecting_centers.phone")}
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>

          </Col>

          <Col md={24} sm={24} xs={24}>

            <Form.Item
              name={"address"}
              label={t("address")}
              rules={[{ required: true }]}>
              <Input.TextArea autoComplete="off" />
            </Form.Item>

          </Col>

          <Col md={24} sm={24} xs={24}>
            <Form.Item
              name={"sub_district"}
              label={t("subdistrict_district_province_zip_code")}
              rules={[{ required: true }]}>
              <Select
                initialvalues={"-"}
                autoComplete="off"
                showSearch
                labelInValue
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceSubdistrict}
                options={subdistricts}
                allowClear={"-"} />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item  
              name={"license_number"}
              label={t("collecting_centers.license_number")}>
                <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item  
              name={"license_expire_date"}
              label={t("collecting_centers.license_expire_date")}>
                <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  )
}