import React from "react";
import { Row, Col, Modal, Input, theme, Button, Image, Typography, Transfer, Divider  } from 'antd'
import Form from "antd/es/form/Form";
import { UploadOutlined } from '@ant-design/icons';
import imageDefault from "../../assets/placeholderImage.png"
import { useTranslation } from 'react-i18next';
import TextArea from "antd/es/input/TextArea";
import ImgCrop from 'antd-img-crop';
import * as _ from 'lodash';
import ErrorMessages from "../common/ErrorMessages";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_DDS, URL_MEMBER } from "../../constants/urls";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function LogoSettingModal(props) {
  const {open, target, onClose, onUpdate} = props;
  const { t, i18n } = useTranslation();
  const uploadLogoRef = React.useRef(null);
  const [logoImageData, setLogoImageData] = React.useState(null);
  const [uploadLogoFile, setUploadLogoFile] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const isCreate = target === 'add';
  const [form] = Form.useForm();
  const factories = Form.useWatch('factories', form);

  const factoryDropdown = useDropdownItem({
    url: URL_MEMBER.FACTORY,
    manual: true, pagination: true, 
    params: { page_size: 999 },
    key: 'id', value: 'id', 
    label_renderer: (item) => i18n.language === "en" ? (item.name_en ? item.name_en : item.name) : (item.name ? item.name : item.name_en) ,
  });

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleOk = async() => {
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      if(isCreate){
        const response = await POST(URL_DDS.DDS_LOGO, data)
        if (logoImageData){
          const id = response ? response.data.id : target.id
          const image = {logo : logoImageData}
          await PATCH(`${URL_DDS.DDS_LOGO}${id}/`, image, true)  
        }
      } else {
        await PATCH(`${URL_DDS.DDS_LOGO}${target.id}/`, data,)
        if (logoImageData){
          const image = {logo : logoImageData}
          await PATCH(`${URL_DDS.DDS_LOGO}${target.id}/`, image, true)  
        }
      }
      props.onSaved()
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      factoryDropdown.fetchOptions();
      if(!isCreate){
        form.setFieldsValue({...target})
      }
    } else {
      form.resetFields(); 
      setErrorMessages(null)
      setUploadLogoFile(null)
    }
  },[open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={ isCreate ? t("add_logo") : t("edit_logo") }
      onOk={handleOk}
      okText={isCreate ? t('add') : t('edit')}
      cancelText={t('cancel')}
      width={900}
      style={{top: 20}}
      okButtonProps={{ style : { backgroundColor : isCreate ? colorPrimary : colorWarning}}}
    >
      <ErrorMessages message={errorMessages}/>
      <Form
        style={{marginTop: 0}}
        form={form}
        layout="vertical"
        autoComplete="off">
          
          <Row gutter={8}>
            <Col md={12} sm={16} xs={24}>
            
              <div style={{ textAlign: "center", height : 210}} className='section-wrapper'>
                <Image
                  src={uploadLogoFile 
                    ? uploadLogoFile 
                    : (target && _.get(target, 'logo', false) ) 
                    ? _.get(target, 'logo', null)
                    : imageDefault}
                    style={{ height : 100 }}
                />
                <div>
                  <Button 
                    style={{marginTop: 8}}
                    icon={<UploadOutlined />} 
                    onClick={() => uploadLogoRef.current.click()}
                    >
                      {t("upload_logo")}
                  </Button>
                </div>
                <div style={{marginTop: 12}}>
                  <Typography.Text>({t("logo_auto_resize")})</Typography.Text>
                </div>
              </div>

            </Col>
          
            <Col md={12} sm={16} xs={24}>
            <div style={{height: 210}} className='section-wrapper'>
              <Form.Item name={"name"} label={t("company_name")} style={{marginTop: 12}} rules={[{ required: true }]}>
                <Input placeholder={`${t("company_name")}`} defaultValue={''}/>
              </Form.Item>
              <Form.Item name={"address"} label={t("address")} style={{marginTop: 12}} rules={[{ required: true }]}>
                <TextArea rows={3} placeholder={`${t("address")}.....`} defaultValue={''}/>
              </Form.Item>
            </div>
            </Col>
          </Row>
          
          <Divider/>

          <Row>
            <Form.Item name={"factories"} label={`${t("factory")} (${t("logo_factory_info")})`}>
              <Transfer
                dataSource={factoryDropdown.options}
                titles={['', 'In Use']}
                oneWay
                showSearch
                targetKeys={factories}
                render={item => `${item.name_en} (${item.short_name})`}
                listStyle={{
                  width: 400,
                  height: 300,
                }}
                onChange={keys => {
                  form.setFieldValue('factories', keys)
                }}
              />
            </Form.Item>
          </Row>
        </Form>


      <ImgCrop aspect={1/1} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadLogoRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadLogoFile(URL.createObjectURL(evt.target.files[0]))
              setLogoImageData(evt.target.files[0])
              evt.target.value = null  // clear reference to enable select same file}
          }}}
        />
      </ImgCrop>

    </Modal>
  )
}