import { Button, Form, Input, Modal, Space , Checkbox, theme, Row, Col, DatePicker, Select, Spin, Image } from 'antd';
import React from 'react';
import Header from '../common/Header';
import ErrorMessages from '../common/ErrorMessages';
import { GET, PATCH, POST, } from '../../frameworks/HttpClient';
import { URL_DDS, URL_DELIVERY } from '../../constants/urls';
import * as _ from 'lodash';
import TSTable from '../common/TSTable';
import moment from 'moment';
import { DATE_FORMAT , HUMAN_DATE_FORMAT } from '../../constants/string';
import ColorButton from '../common/ColorButton';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { useTranslation } from 'react-i18next';
import DeliveryMobileList from '../delivery/DeliveryMobileList';
import dayjs from 'dayjs';
import { useUserContext } from '../../hooks/UserSettingProvider';
import useDropdownItem from "../../hooks/useDropdownItem";

export default function ShippingInstructionModal(props) {
  const {
    target,
    open,
    onClose,
    onSuccess,
  } = props;

  const [form] = Form.useForm();
  const { token: { colorWarning, colorPrimary } } = theme.useToken();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { t, i18n } = useTranslation();
  const isCreated = target == 'add';
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);//Checkbox
  const [fetchingCustomer, setFetchingCustomer] = React.useState(false);
  const [debouncedCustomer, setDebouncedCustomer] = React.useState(''); 
  const [customer, setCustomer] = React.useState((null))
  const [fetchingProducts, setFetchingProducts] = React.useState(false);
  const [debouncedProducts, setDebouncedProducts] = React.useState(''); 
  const [products, setProducts] = React.useState(null)
  const selected_dds_logo = Form.useWatch('dds_logo', form)
  const selected_dds_signature = Form.useWatch('dds_signature', form)
  const { user } = useUserContext();

  const fetchData = async () => { 
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_DELIVERY.SHIPPING_INSTRUCTION}${target}/`)
      form.setFieldsValue({
        ...response.data,
        production_date: response.data.production_date ? dayjs(response.data.production_date) : null,
      })
      setSelectedRowKeys(_.get(response, 'data.delivery_transactions', []))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const ddsLogoDropdown = useDropdownItem({
    url: URL_DDS.DDS_LOGO,
    manual: false, pagination: true, 
    params: { page_size: 999, factory: _.get(user, 'factory', '') },
    key: 'id', value: 'id', 
    label_renderer: item =>  item.name,
  });

  const ddsSignatureDropdown = useDropdownItem({
    url: URL_DDS.DDS_SIGNATURE,
    manual: false, pagination: true,
    params: { page_size: 999, factory: _.get(user, 'factory', '') },
    key: 'id', value: 'id',
    label_renderer: item => item.name,
  });

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      if (!selectedRowKeys || selectedRowKeys.length === 0) {
        throw new Error(t("please_select_a_rubber_delivery_note"));
      }
      
      form.setFieldValue('delivery_transactions', selectedRowKeys);
      const data = await form.validateFields();

      if (data["production_date"])
        data["production_date"] = data.production_date.format(DATE_FORMAT);
      
      if (!data["customer"]){
        data["customer"] = null;
      }

      if(!data["dds_logo"]) {
        data["dds_logo"] = null
      }

      if(!data["dds_signature"]) {
        data["dds_signature"] = null
      }

      console.log(data)

      if (isCreated) {
        const response = await POST(URL_DELIVERY.SHIPPING_INSTRUCTION, data);
        onSuccess(response.data);
      } else {
        const response = await PATCH(`${URL_DELIVERY.SHIPPING_INSTRUCTION}${target}/`, data);
        onSuccess(response.data);
      }
    } catch (error) {
      setErrorMessages(error.errorMessages || [error.message]);
    } finally {
      setLoading(false);
    }
  };

  const handleChecked = (select, e) =>{
    if(e.target.checked){
      setSelectedRowKeys([...selectedRowKeys, select.id])  
    } else if (!e.target.checked){
      setSelectedRowKeys(selectedRowKeys.filter((el) => el !== select.id))
    }
  }

  const handleCheckedMobile = (select, e) =>{
    if(e){
      setSelectedRowKeys([...selectedRowKeys, select.id])  
    } else if (!e){
      setSelectedRowKeys(selectedRowKeys.filter((el) => el !== select.id))
    }
  }

  const fetchCustomer = async () => {
    setFetchingCustomer(true);
    try {
    const response = await GET(`${URL_DELIVERY.SI_CUSTOMER}`, { search : debouncedCustomer, factory : _.get(user, 'factory', '')})
      setCustomer(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetchingCustomer(false);
    }
  }

  const fetchProduct = async () => {
    setFetchingProducts(true);
    try {
      const response = await GET(`${URL_DDS.EXPORTED_PRODUCT}`, { search: debouncedProducts, factory: _.get(user, 'factory', ''), page_size: 999 })
      setProducts(response.data.results.map((item) => {
        return {
          ...item,
          label: `${item.name} - ${item.hs_code}`,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetchingProducts(false);
    }
  }

  const debounceCustomer = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCustomer(_searchVal) }, 800),
    []
  );

  const debounceProduct = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedProducts(_searchVal) }, 800),
    []
  );

  React.useEffect(() => {
      fetchCustomer();
  }, [debouncedCustomer])

  React.useEffect(() => {
    fetchProduct();
  }, [debouncedProducts])

  React.useEffect(() => {
    if (target) {
      if (isCreated) {
        form.resetFields()
      } else {
        fetchData();
      }
    }
  }, [target])

  React.useEffect(() => {
    if(open === false) {
      form.resetFields()
      setSelectedRowKeys([]);
    }
    // else {
    //   ddsLogoDropdown.fetchOptions()
    // }
  }, [open])


  const columns = [
    {
      title: t("select"),
      width: 50,
      dataIndex: '',
      render : (select) => {
      return(
        <div style={{ textAlign : 'center'}}>
        <Checkbox 
          onChange={(e) => handleChecked(select, e)}
          checked={selectedRowKeys.includes(select.id)}
          />
        </div>
      )
      }
    },
    {
      title: t("factory_del.del_number"),
      dataIndex: 'code',
      sorter: true,
      searcher: true,
    },
    {
      title: t("factory_del.ri_number"),
      dataIndex: 'ri_no',
      searcher: true,
    },
    {
      title: t("factory_del.ac_fl_number"),
      dataIndex: 'acfl_no',
      searcher: true,
    },
    {
      title: t("factory_del.factory_arrival_date"),
      dataIndex: 'receive_date',
      searcher: true,
      dateSearcher: true,
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT)
    },
    {
      title: t("factory_del.collecting_center_name"),
      dataIndex: 'sender_name',
      sorter: true,
      dateSearcher: true,
    },
    {
      title: t("factory_del.product"),
      dataIndex: 'product_name',
      sorter: true,
      searcher: true,
    },
    {
      title: t("factory_del.lot_number"),
      width: 180,
      dataIndex: 'stocks_number',
      render : (value) => {
        return(
            value.map( item => <div>{item}</div>)
        )
      }
    },
  ]

  return (
    <div>
      <Modal
        open={open}
        footer={[]}
        onCancel={onClose}
        width={1250}
        >
        <Header title={t("export_list")}/>
        <ErrorMessages style={{ marginTop: '1rem' }} message={errorMessages}/>
        <Form style={{ marginTop: '1rem' }} layout="vertical" form={form}>
          <Row gutter={16}>
            <Col xl={8} md={12} sm={12} xs={24}>
              <Form.Item label='เลขที่ SI' name='code' rules={[{ required: true }]}>
                <Input/>
              </Form.Item>
            </Col>

            {user.enable_dds && 
              <Col xl={8} md={12} sm={12} xs={24}>
                <Form.Item label='สินค้าส่งออก (Product)' name='exported_product' rules={[{ required: true }]}>
                  <Select
                    initialvalues={"-"}
                    autoComplete="off"
                    showSearch
                    // labelInValue 
                    filterOption={false}
                    notFoundContent={fetchingProducts ? <Spin size="small" /> : "-"}
                    onSearch={debounceProduct}
                    options={products}
                    allowClear={"-"} />
                </Form.Item>
              </Col>
            }

            { !user.enable_dds && 
              <Col xl={8} md={12} sm={12} xs={24}>
                <Form.Item label='สินค้าส่งออก (Product)' name='finish_product' rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            }

            {/* input customer si */}
            <Col xl={8} md={12} sm={12} xs={24}>
              <Form.Item label='ลูกค้า (Customer)' name='customer' rules={[{ required: false }]}>
                <Select
                  initialvalues={"-"}
                  autoComplete="off"
                  showSearch
                  // labelInValue 
                  filterOption={false}
                  notFoundContent={fetchingCustomer ? <Spin size="small" /> : "-"}
                  onSearch={debounceCustomer}
                  options={customer}
                  allowClear={"-"} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xl={5} md={7} sm={7} xs={15}>
              <Form.Item label='วันที่ผลิต (Production Date)' name='production_date' rules={[{ required: false }]}>
                <DatePicker style={{ width: '100%' }} format={DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xl={5} md={7} sm={7} xs={15}>
              <Form.Item label='จำนวนส่งออก (QTY in Kgs.)' name='quantity' rules={[{ required: false }]}>
                <Input type="number"/>
              </Form.Item>
            </Col>
            <Col xl={7} md={10} sm={10} xs={20}>
              <Form.Item label='Invoice Number' name='invoice_number' rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xl={7} md={10} sm={10} xs={20}>
              <Form.Item label='External Reference Code' name='ext_ref_code' rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {user.enable_dds && 
            <Row gutter={24}>
              <Col xl={5} md={7} sm={7} xs={15}>
                <Form.Item label='DDS Logo' name='dds_logo' rules={[{ required: false }]}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t("select")}
                    options={ddsLogoDropdown.options}
                  />
                </Form.Item>
                {selected_dds_logo && ddsLogoDropdown.options != null &&
                  <Image
                    height={100}
                    src={_.get(_.find(ddsLogoDropdown.options, (logo) => logo.id === selected_dds_logo), 'logo', null)}
                  />
                }
              </Col>
              <Col xl={5} md={7} sm={7} xs={15}>
                <Form.Item label='DDS Signature' name='dds_signature' rules={[{ required: false }]}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t("select")}
                    options={ddsSignatureDropdown.options}
                  />
                </Form.Item>
                {selected_dds_signature && ddsSignatureDropdown.options != null &&
                  <Image
                    height={100}
                    src={_.get(_.find(ddsSignatureDropdown.options, (logo) => logo.id === selected_dds_signature), 'signature', null)}
                  />
                }
              </Col>
            </Row>
          }
          <Form.Item name='delivery_transactions'>
            { isOnMobile ? (
                <DeliveryMobileList checkedList={selectedRowKeys} handleCheck={handleCheckedMobile}/>
              ) : (
                <TSTable
                  columns={columns}
                  url={`${URL_DELIVERY.DELIVERY_TRANSACTION}`}
                  rowKey={'id'}
                  size={'small'}
                  pageSize={10}/>
              ) 
            }
          </Form.Item>
          <Form.Item>
            <Space style={{ float: 'right' }}>
              <ColorButton 
                style={{ float: 'right' }} 
                loading={loading} 
                onClick={onClose}>
                {t("cancel")}
                </ColorButton>
              <ColorButton 
                type='primary'
                loading={loading} 
                onClick={handleSubmit} 
                override={isCreated ? colorPrimary : colorWarning }>
                  { isCreated  ?   t("add") : t("edit") }
                  </ColorButton>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}