import React from "react";
import { Input, Modal, theme, Select, Transfer, Row, Col  } from 'antd'
import Form from "antd/es/form/Form";
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import ErrorMessages from "../common/ErrorMessages";
import { URL_DDS, URL_MEMBER } from "../../constants/urls";
import { PATCH, POST } from "../../frameworks/HttpClient";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function FactoryProductModal(props) {
  const {open, target, onClose, onUpdate} = props;
  const { t, i18n } = useTranslation();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const isCreate = target === 'add';
  const [form] = Form.useForm();
  const factories = Form.useWatch('factories', form);

  const factoryDropdown = useDropdownItem({
    url: URL_MEMBER.FACTORY,
    manual: true, pagination: true, 
    params: { page_size: 999 },
    key: 'id', value: 'id', 
    label_renderer: (item) => i18n.language === "en" ? (item.name_en ? item.name_en : item.name) : (item.name ? item.name : item.name_en) ,
  });

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleOk = async() => {
    try {
      const data = await form.validateFields();
      if(isCreate){
        await POST(URL_DDS.EXPORTED_PRODUCT, data)
      } else {
        await PATCH(`${URL_DDS.EXPORTED_PRODUCT}${target.id}/`, data)
      }
      props.onSaved()
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      factoryDropdown.fetchOptions();
      if(!isCreate){
        form.setFieldsValue({...target})
      }
    } else {
      form.resetFields(); 
      setErrorMessages(null)
    }
  },[open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={isCreate ? t("add_sign") : t("edit_sign")}
      onOk={handleOk}
      okText={isCreate ? t('add') : t('edit')}
      cancelText={t('cancel')}
      width={900}
      style={{ top: 20 }}
      okButtonProps={{ style : { backgroundColor : isCreate ? colorPrimary : colorWarning}}}
    >
      <ErrorMessages message={errorMessages}/>
      <Form
        style={{marginTop: 0}}
        form={form}
        layout="vertical"
        autoComplete="off">
        <div style={{ textAlign: "center", height: 100 }} className='section-wrapper'>
          <Row gutter={8}>
            <Col md={12} sm={16} xs={24}>
              <Form.Item 
                name={"name"} 
                label={t("product_name")} 
                style={{marginTop: 12}}
                rules={[{ required: true }]}>
                <Input placeholder={`${t("product_name")}`} defaultValue={''}/>
              </Form.Item>
            </Col>
            <Col md={12} sm={16} xs={24}>
              <Form.Item 
                name={"hs_code"} 
                label={t("hs_code")} 
                style={{marginTop: 12}}
                rules={[{ required: true }]}>
                <Input placeholder={`${t("hs_code")}`} defaultValue={''}/>
              </Form.Item>
            </Col>
          </Row>
          </div>

          <Row>
          <Form.Item name={"factories"} label={`${t("factory")} (${t("logo_factory_info")})`} style={{marginTop: 12}}>
            <Transfer
              dataSource={factoryDropdown.options}
              titles={['', 'In Use']}
              oneWay
              showSearch
              targetKeys={factories}
              render={item => `${item.name_en} (${item.short_name})`}
              listStyle={{
                width: 400,
                height: 300,
              }}
              onChange={keys => {
                form.setFieldValue('factories', keys)
              }}
            />
          </Form.Item>
          </Row>

        </Form>
    </Modal>
  )
}