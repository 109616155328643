import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateOutlet from "./PrivateOutlet";
import NotFoundScreen from "../screens/NotFoundScreen";
import LoginScreen from "../screens/LoginScreen";
import LiffLoginScreen from "../screens/LiffLoginScreen";
import MenuScreen from "../screens/MenuScreen";
import MemberRegisterScreen from "../screens/MemberRegisterScreen";
import FarmerPlantationsScreen from "../screens/FarmerPlantationsScreen";
import PlantationDetailScreen from "../screens/PlantationDetailScreen";
import ScaleScreen from "../screens/ScaleScreen";
import DeliveryOrderScreen from "../screens/DeliveryOrderScreen";
import ShippingInfomationScreen from "../screens/ShippingInfomationScreen";
import DashboardDetailCCScreen from "../screens/DashboardDetailCCScreen";


export const PATH = {
  LOGIN: "/login",
  LIFF_LOGIN: "/liff-login",
  MAIN: "/",

  // path for officer
  OFFICER_DASHBOARD: "/officer-dashboard",
  PLANTATIONS_MANAGER: "/plantations-manager",
  OFFICER_DELIVERY_ORDER: "/officer-del",
  MEMBERS: "/members",
  COLLECTING_CENTERS: "/collection-centers",
  FACTORIES: "/factories",
  GARDENER: "/gardener",
  OFFICER_USERS: "/officer-users",
  DDS_SETTING: "/dds-setting",
  // USER_GROUP : "/user-group",
  OFFICER_REPORTS: "/officer-reports",
  DELIVERY_ORDER: "/del",

  // path detail
  MEMBER_REGISTER: "/member-register",
  FARMER_PLANTATIONS: "/farmer-plantations",
  PLANTATION_DETAIL: "/plantation-detail",
  
  // path collection
  SCALE: "/scale",
  STOCK: "/cc-stock",
  TRANSACTION: "/transaction",
  CC_SETTING: "/cc-setting",
  CC_ADMIN : "/cc-admin",
  CC_CUSTOMER: "/cc-customer",
  CC_PRODUCT: "/cc-product",
  CC_VEHICLE: "/cc-vehicle",
  COLLECTING_CENTER_DASHBOARD: "/cc-dashboard",
  CC_REPORT: '/cc-report',

  // path factory
  FACTORY_DASHBOARD: "factory-dashboard",
  FACTORY_DELIVERY_ORDER: "/factory-del",
  FACTORY_SHIPPING_INSTRUCTION: "/factory-si",
  FACTORY_CUSTOMER: "/factory-customer",
  SHIPPING_INFOMATION:"/shipping-infomation",
  FACTORY_PROFILE: "/factory-profile",
  FACTORY_REPORT: '/factory-report',

  // path farmer
  MY_PROFILE: "/my-profile",
  MY_PLANTATIONS: "/my-plantations",
}

export default function CustomRoutes() {
  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />
      <Route exact path={PATH.LIFF_LOGIN} element={<LiffLoginScreen/>} />

      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route path={`${PATH.MEMBER_REGISTER}`} element={<MemberRegisterScreen />}/>
        <Route path={`${PATH.MEMBER_REGISTER}/:id`} element={<MemberRegisterScreen />}/>
        <Route path={`${PATH.FARMER_PLANTATIONS}/:id`} element={<FarmerPlantationsScreen />}/>
        <Route path={`${PATH.PLANTATION_DETAIL}`} element={<PlantationDetailScreen/>}/>
        <Route path={`${PATH.PLANTATION_DETAIL}/:id`} element={<PlantationDetailScreen />}/>
        <Route path={`${PATH.COLLECTING_CENTER_DASHBOARD}/:id`} element={<DashboardDetailCCScreen/>}/>
        <Route path={`${PATH.PLANTATION_DETAIL}/:id/:plantId`} element={<PlantationDetailScreen />}/>
        <Route path={`${PATH.SCALE}/:id`} element={<ScaleScreen />}/>
        <Route path={`${PATH.DELIVERY_ORDER}/:id`} element={<DeliveryOrderScreen/>} />
        <Route path='' element={<MenuScreen/>}/>
        <Route path=':menu/*' element={<MenuScreen/>}/>
      </Route>

      <Route exact path={`${PATH.SHIPPING_INFOMATION}/:id`} element={<ShippingInfomationScreen />} />
      <Route path="*" element={<NotFoundScreen />} /> 
    </Routes>
  )
}