import React from "react";
import { 
  ShopOutlined, 
  UserOutlined, 
  MailOutlined, 
  PhoneOutlined, 
  AuditOutlined, 
  EditOutlined, 
  FieldNumberOutlined, 
  EnvironmentOutlined, 
  CalendarOutlined, 
  SafetyCertificateOutlined 
} from '@ant-design/icons';
import { Card, theme, message, Divider } from "antd";
import { useTranslation } from 'react-i18next';
import { GET } from "../frameworks/HttpClient";
import { useWindowDimensionContext } from "../hooks/WindowDimensionProvider";
import { useUserContext } from "../hooks/UserSettingProvider";
import DetailItem from "../components/common/DetailItem";
import { URL_MEMBER } from "../constants/urls";
import { LANGUAGE } from "../constants/string";
import ColorButton from "../components/common/ColorButton";
import LogoutModal from "../components/user/LogoutModal";
import FactoryModal from "../components/factory/FactoryModal";


export default function FactorySettingView() {
  const { user } = useUserContext();
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const [targetFactory, setTargetFactory] = React.useState();
  const [data, setData] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { t, i18n } = useTranslation();

  const { token: { colorWarning } } = theme.useToken();

  const fetchData = async () => {
    try {
      const response = await GET(`${URL_MEMBER.FACTORY}${user.factory}/`)
      setData(response.data)
    } catch (error) {
      message.error(error.errorMessage);
    }
  }

  React.useEffect(() => {
    if (user)
      fetchData();
  }, [])

  return (
    <div>
      <Card>
      <Divider style={{marginTop: 8, marginBottom: 8}}>{t("factory_detail")}</Divider>
        <DetailItem
          style={{ padding: 10 }}
          title={t("factory_name")}
          titleIcon={<ShopOutlined />}
          detail={data ? data.name : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("factory_name_en")}
          titleIcon={<ShopOutlined />}
          detail={data ? data.name_en : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("factory_short_name")}
          titleIcon={<ShopOutlined />}
          detail={data ? data.short_name : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("tax_identification_number")}
          titleIcon={<FieldNumberOutlined />}
          detail={data ? data.invoice_id : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("address")}
          titleIcon={<EnvironmentOutlined />}
          detail={data ? data.address : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("collecting_centers.license_number")}
          titleIcon={<AuditOutlined />}
          detail={data ? data.license_number : ""}
        />
        
        <DetailItem
          style={{ padding: 10 }}
          title={t("collecting_centers.license_expire_date")}
          titleIcon={<CalendarOutlined />}
          detail={data ? data.license_expire_date : ""}
        />
        
        <DetailItem
          style={{ padding: 10 }}
          title={t("fsc_license_number")}
          titleIcon={<SafetyCertificateOutlined />}
          detail={data ? data.fsc_number : ""}
        />

        <Divider style={{marginTop: 8, marginBottom: 8}}>{t("contact_information")}</Divider>

        <DetailItem
          style={{ padding: 10 }}
          title={t("profiles.full_name")}
          titleIcon={<UserOutlined />}
          detail={data ? `${data.full_name}` : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("profiles.email")}
          titleIcon={<MailOutlined />}
          detail={data ? data.email : ""}
        />

        <DetailItem
          style={{ padding: 10 }}
          title={t("collecting_centers.phone")}
          titleIcon={<PhoneOutlined />}
          detail={data ? data.phone : ""}
        />

        <div style={{ textAlign: 'center' }}>
            <ColorButton
              type={"primary"}
              style={{background: colorWarning}}
              icon={<EditOutlined />}
              danger
              onClick={() => setTargetFactory(data)}>
            {t("edit")}
          </ColorButton>
        </div>
        
      </Card>

      {isOnMobile && (
        <div style={{ textAlign: 'center' }}>
          <ColorButton
            danger
            size='large'
            type='primary'
            override={colorWarning}
            style={{ marginTop: 16, marginRight: 10}}
            onClick={() => setOpenLogoutModal(true)}
          >
            {t("profiles.sign_out")}
          </ColorButton>
        </div>
        )}

      <FactoryModal
        open={(targetFactory) ? true : false}
        target={targetFactory}
        onUpdated={() => {
          if (targetFactory)
            setTargetFactory(null)
            fetchData();
        }}
        onClose={() => {
            setTargetFactory(null)
        }}/>

      <LogoutModal
      open={openLogoutModal}
      onClose={() => {
        setOpenLogoutModal(false);
      }}/>

    </div>
  )
}